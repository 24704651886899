/**
 * 称重记录
 */
import { Button, Table, Select, message, Cascader, Card, Image } from "antd"
import React, { useEffect, useState, useRef } from "react"
import dayjs from "dayjs"
import styles from "./Index.module.css"
import PubInput from "../../../components/PubInput"
import { GetGoodsWeighDetail, ShopTreeList } from "../../services/datareport"
import { new_baseAuthURL } from "../../../utils/newRequest"
import { stringify } from "qs"
import { UserInfo } from "../../services/systemset"
import {
  PubRangePicker,
  HandDefaultTime,
} from "../../../components/PubRangePicker"

const { Option } = Select

// 开始时间
let ST = HandDefaultTime?.startDateValueOf
// 结束时间
let ET = HandDefaultTime?.endDateValueOf
// 项目id
const projectId = localStorage.getItem("uid")

function Index(props) {
  //shopId：门店id：门店id,staffName:员工,skuCode:商品，recoredId:精确查询id,specific_time:时间
  // console.log(props)
  let JumpToHereInfo = props.location.state
  //从其他页面跳转来带时间的话做处理  -----
  let JST = "" // 开始
  let JET = "" // 结束
  let JAT = "" // 所有
  if (JumpToHereInfo?.tabKey == 1) {
    //跳转开始时间
    // JST = JumpToHereInfo?.specific_time ? JumpToHereInfo?.specific_time : ""
    //跳转结束时间
    JST = JumpToHereInfo?.specific_time ? JumpToHereInfo?.specific_time : ""
    ST = JST
      ? dayjs(JumpToHereInfo?.specific_time).startOf("day").valueOf()
      : ST
    ET = JST ? dayjs(JumpToHereInfo?.specific_time).endOf("day").valueOf() : ET
    JAT = [
      dayjs(JumpToHereInfo?.specific_time).startOf("day"),
      dayjs(JumpToHereInfo?.specific_time).endOf("day"),
    ]
  } else if (JumpToHereInfo?.tabKey == 2) {
    const NewTime = JumpToHereInfo?.specific_time[1].split("-")
    JST = NewTime ? NewTime[0] : ""
    //跳转结束时间
    JET = NewTime ? NewTime[1] : ""
    ST = JST
      ? dayjs(dayjs(JST).format("YYYY-MM-DD") + " " + "00:00:00").valueOf()
      : ST
    ET = JET
      ? dayjs(dayjs(JET).format("YYYY-MM-DD") + " " + "23:59:59").valueOf()
      : ET
    JAT = [dayjs(JST), dayjs(JET)]
  } else if (JumpToHereInfo?.tabKey == 3) {
    //跳转开始时间
    JST = JumpToHereInfo?.specific_time ? JumpToHereInfo?.specific_time : ""
    ST = JST
      ? dayjs(JumpToHereInfo?.specific_time).startOf("month").valueOf()
      : ST
    ET = JST
      ? dayjs(JumpToHereInfo?.specific_time).endOf("month").valueOf()
      : ET
    JAT = [
      dayjs(JumpToHereInfo?.specific_time).startOf("month"),
      dayjs(JumpToHereInfo?.specific_time).endOf("month"),
    ]
  } else {
    JAT = HandDefaultTime?.TotalTime
    ST = HandDefaultTime?.startDateValueOf
    ET = HandDefaultTime?.endDateValueOf
  }
  //--------------------------

  //定义参数
  const [WinHeight, setWinHeight] = useState() // 动态获取浏览器高度
  const [total, setTotal] = useState() //每页显示的行数
  const [loading, setLoading] = useState(false)
  const [isShowBut, setIsShowBut] = useState(false) // 是否展示导出数据按钮
  //
  const [dataList, setDataList] = useState([]) //表格列表
  const [shopList, setShopList] = useState([]) //门店设备列表
  const [GiveParams, setGiveParams] = useState({
    timeArr: JAT,
    startDate: ST,
    endDate: ET,
    pageNum: 1,
    pageSize: 20,
    shopId: Number(JumpToHereInfo?.shopId) || "",
    deviceSn: "",
    operator: JumpToHereInfo?.staffName || "",
    barCode: "",
    isChangePrice: "",
    skuCode: "",
    skuName: JumpToHereInfo?.skuCode || "",
    // ids: [],
    projectId: projectId, //项目id
    chooseResultType: undefined, // 称重类型
    id: JumpToHereInfo?.recoredId || undefined, //精确查询的id
  })

  //保证每次拿到的都是准确的值
  const updataRef = useRef({
    GiveParamsRef: {},
  })

  useEffect(() => {
    updataRef.current = {
      GiveParamsRef: GiveParams,
    }
  }, [GiveParams])

  useEffect(() => {
    //调用获取表格列表数据
    setTimeout(getGoodsWeighDetail, 0)
    //调用门店设备
    getShopTreeList()

    /**
     * 判断是是否有称重记录导出这个页面，是否展示导出记录按钮
     */
    getUserInfoNow()
  }, [])

  /**
   * 判断是是否有称重记录导出这个页面，是否展示导出记录按钮
   */
  const getUserInfoNow = async () => {
    try {
      const { code, data, msg } = await UserInfo()
      if (code != 200) {
        message.error(msg)
        return
      }
      const NewMenu = data?.menus || []
      if (NewMenu.length > 0) {
        const NewMenuChild =
          NewMenu && NewMenu.filter((v) => v.menuName === "绩效管理")
        if (NewMenuChild && NewMenuChild[0].childMenus.length > 0) {
          const exists =
            NewMenuChild[0].childMenus.find(
              (item) => item.menuName === "称重记录导出"
            ) !== undefined
          setIsShowBut(exists)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  /**
   * 自定义表格高度
   */
  useEffect(() => {
    if (sessionStorage.getItem("INNERHEIGHT") < 700) {
      setWinHeight(sessionStorage.getItem(700))
    } else {
      setWinHeight(sessionStorage.getItem("INNERHEIGHT"))
    }
  }, [sessionStorage.getItem("INNERHEIGHT")])

  //门店设备
  const getShopTreeList = async (value) => {
    try {
      const params = {
        code: value,
        districtId: "",
      }
      const res = await ShopTreeList(params)
      if (!res) {
        return false
      }
      const { code, data, msg } = res
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data.length > 0) {
        data.map((v) => {
          v.value = v.shopId
          v.label = v.name
          if (v.deviceList) {
            v.deviceList.map((w) => {
              w.value = w.deviceSn
              w.label = w.cdKeyCode
            })
          }
          v.children = v.deviceList
        })
        // 合并原始数组与查询出的数组，并去重
        const NewArr = data.concat(shopList)
        const obj = {}
        let NewArr1 = []
        for (let i = 0; i < NewArr.length; i++) {
          if (!obj[NewArr[i]["shopId"]]) {
            obj[NewArr[i]["shopId"]] = 1
            NewArr1.push(NewArr[i])
          }
        }
        setShopList(NewArr1)
      }
    } catch (error) {
      console.log(error)
    }
  }

  //获取表格列表数据
  const getGoodsWeighDetail = async () => {
    try {
      const { GiveParamsRef } = updataRef.current
      setLoading(true)
      const { code, msg, data } = await GetGoodsWeighDetail(GiveParamsRef)
      if (code != 200) {
        setLoading(false)
        message.error(msg)
        return
      }
      setLoading(false)
      setTotal(data.total) //数据总条数
      setDataList(data.rows || []) //数据列表
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  //导出
  const getExport = async () => {
    const { GiveParamsRef } = updataRef.current
    GiveParamsRef.projectId = localStorage.getItem("uid")
    GiveParamsRef.timeArr = ""
    try {
      const downloadUrl = `${new_baseAuthURL}/data/center/v1/weigh/exportStaffWeightDetailByProject?${stringify(
        GiveParamsRef
      )}`
      window.localStorage.setItem("WeightRecordDownloadUrl", downloadUrl)
      window.open("/download.html")

      // GiveParamsRef.projectId = localStorage.getItem("uid")
      // console.log("导出数据传值：", GiveParamsRef)
      // const res = await ExportStaffWeightDetailByProject(GiveParamsRef, (e) => {
      //   console.log("下载进度", e)
      // })
      // console.log("下载完成")
    } catch (error) {
      console.log(error)
    }
  }

  //分页
  const onTableChange = () => {
    const paginationProps = {
      showSizeChanger: false,
      showQuickJumper: false,
      showTotal: () => `共${total}条`,
      pageSize: GiveParams.pageSize,
      current: GiveParams.pageNum,
      total: total,
      onChange: (current) => {
        setGiveParams({
          ...GiveParams,
          pageNum: current,
        })
        setTimeout(getGoodsWeighDetail, 0)
      },
    }
    return paginationProps
  }

  const columns = [
    {
      title: "称重时间",
      dataIndex: "weighDateYear",
      ellipsis: { showTitle: true },
      key: "weighDateYear",
      width: 200,
    },
    {
      title: "门店名称",
      dataIndex: "shopName",
      ellipsis: { showTitle: true },
      key: "shopName",
      width: 200,
    },
    {
      title: "门店编号",
      dataIndex: "shopCode",
      ellipsis: { showTitle: true },
      key: "shopCode",
      width: 200,
    },
    {
      title: "所属区域",
      dataIndex: "owningRegion",
      ellipsis: { showTitle: true },
      key: "owningRegion",
      width: 200,
    },
    {
      title: "秤号",
      dataIndex: "scaleNo",
      ellipsis: { showTitle: true },
      key: "scaleNo",
      width: 100,
    },
    {
      title: "选择商品",
      dataIndex: "skuName",
      ellipsis: { showTitle: true },
      key: "skuName",
      width: 200,
    },
    {
      title: "识别商品",
      dataIndex: "allOutput",
      ellipsis: { showTitle: true },
      key: "allOutput",
      width: 200,
      render: (info, rowInfo) => {
        // if (info && typeof info !== "object") {
        if (info) {
          let NewData
          if (typeof info !== "object") {
            NewData = JSON.parse(info)
          } else {
            NewData = info
          }
          return (
            <div>
              {NewData.map((v, i) => {
                return (
                  <div
                    key={i}
                    className={styles.newweightrecord_table_allOutput}
                  >
                    {i == 0 ? (
                      <>
                        {" "}
                        首位： {v.name} ({v.price ? v.price / 100 : 0}元/
                        {rowInfo.priceUnit == 1
                          ? "个"
                          : rowInfo.priceUnit == 0
                          ? "kg"
                          : ""}
                        )
                      </>
                    ) : i == 1 ? (
                      <>
                        {" "}
                        次位： {v.name} ({v.price ? v.price / 100 : 0}元/
                        {rowInfo.priceUnit == 1
                          ? "个"
                          : rowInfo.priceUnit == 0
                          ? "kg"
                          : ""}
                        )
                      </>
                    ) : i == 2 ? (
                      <>
                        {" "}
                        三位： {v.name} ({v.price ? v.price / 100 : 0}元/
                        {rowInfo.priceUnit == 1
                          ? "个"
                          : rowInfo.priceUnit == 0
                          ? "kg"
                          : ""}
                        )
                      </>
                    ) : (
                      ""
                    )}{" "}
                  </div>
                )
              })}
            </div>
          )
        }
      },
    },
    {
      title: "称重类型",
      dataIndex: "chooseResultType",
      ellipsis: { showTitle: true },

      key: "chooseResultType",
      width: 200,
      render: (info) => {
        return (
          <>
            {info == 0
              ? "无后续操作"
              : info == 1
              ? "自动打印"
              : info == 2
              ? "识别结果点选"
              : info == 3
              ? "快捷键点选"
              : info == 4
              ? "搜索中选择"
              : info == 5
              ? "重新识别"
              : info == 6
              ? "按称键盘"
              : info == 8
              ? "直打标签"
              : info == 9
              ? "学习模式"
              : info == 10
              ? "临时商品"
              : ""}
          </>
        )
      },
    },
    {
      title: "员工工号",
      dataIndex: "operator",
      ellipsis: { showTitle: true },

      key: "operator",
      width: 120,
    },
    {
      title: "商品PLU",
      dataIndex: "skuCode",
      ellipsis: { showTitle: true },

      key: "skuCode",
      width: 120,
    },
    {
      title: "商品单价（元）",
      dataIndex: "salePrice",
      ellipsis: { showTitle: true },

      key: "salePrice",
      width: 150,
      render: (info, rowInfo) => {
        return (
          <>
            {info == rowInfo.agoPrice ? (
              ""
            ) : rowInfo.agoPrice > 0 ? (
              <div style={{ textDecoration: "line-through" }}>
                {(rowInfo.agoPrice / 100).toFixed(2)}元{" "}
              </div>
            ) : (
              ""
            )}
            {info > 0 ? (info / 100).toFixed(2) : 0}元
            {rowInfo?.priceUnit == 1
              ? "/个"
              : rowInfo?.priceUnit == 0
              ? "/kg"
              : ""}
          </>
        )
      },
    },
    {
      title: "重量/数量",
      dataIndex: "saleWeight",
      ellipsis: { showTitle: true },

      key: "saleWeight",
      width: 150,
      render: (info, rowInfo) => {
        return (
          <>
            {info > 0 ? info : ""}
            {rowInfo?.priceUnit == 1
              ? "/个"
              : rowInfo?.priceUnit == 0
              ? "/kg"
              : ""}
          </>
        )
      },
    },
    {
      title: "商品总价（元）",
      dataIndex: "amount",
      ellipsis: { showTitle: true },

      key: "amount",
      width: 150,
      render: (info) => {
        return <>{info > 0 ? (info / 100).toFixed(2) : 0}元</>
      },
    },
    {
      title: "交易条码",
      dataIndex: "barCode",
      ellipsis: { showTitle: true },

      key: "barCode",
      width: 150,
    },
    {
      title: "设备IP",
      dataIndex: "intranetIp",
      ellipsis: { showTitle: true },

      key: "intranetIp",
      width: 150,
    },
    {
      title: "设备称重时长",
      dataIndex: "weightDuration",
      ellipsis: { showTitle: true },

      key: "weightDuration",
      width: 150,
      render: (info) => {
        return <>{info} 毫秒</>
      },
    },
    {
      title: "设备识别时长",
      dataIndex: "duration",
      ellipsis: { showTitle: true },

      key: "duration",
      width: 150,
      render: (info) => {
        return <>{info} 毫秒</>
      },
    },
    {
      title: "点选行为时长",
      dataIndex: "selectDuration",
      ellipsis: { showTitle: true },

      key: "selectDuration",
      width: 150,
      render: (info) => {
        return <>{info} 毫秒</>
      },
    },
    {
      title: "是否触发损耗",
      dataIndex: "isTriggerAlarm",
      ellipsis: { showTitle: true },

      key: "isTriggerAlarm",
      width: 120,
      render: (info) => {
        return <>{info == 1 ? "是" : "否"}</>
      },
    },
    {
      title: "损耗类型",
      dataIndex: "warnTypes",
      ellipsis: { showTitle: true },

      key: "warnTypes",
      width: 150,
      render: (info) => {
        return (
          <div>
            {info &&
              info.map((v, i) => {
                return v == 1 ? (
                  <div key={i}>监控改价出售</div>
                ) : v == 2 ? (
                  <div key={i}>AI监控：商品被低价出售</div>
                ) : v == 3 ? (
                  <div key={i}>监控指定商品被点选</div>
                ) : (
                  ""
                )
              })}
          </div>
        )
      },
    },
    {
      title: "称重照片",
      dataIndex: "fileName",
      ellipsis: { showTitle: true },

      key: "fileName",
      fixed: "right",
      width: 100,
      render: (info) => {
        return info
          ? (
              <Image
                src={info}
                fallback="error" // 当图片加载失败时触发 onError
                onError={(e) => {
                  e.target.onerror = null // 避免无限循环
                  e.target.alt = "图片不可用" // 设置替代文字
                  e.target.style.display = "none" // 隐藏Image组件
                  return null
                }}
              />
            ) || <span>照片未上传</span>
          : "照片未上传"
      },
    },
  ]

  //查询
  const handSearch = () => {
    setGiveParams({
      ...GiveParams,
      pageNum: 1,
    })
    setTimeout(getGoodsWeighDetail, 0)
  }
  //重置
  const handClear = () => {
    // 重置时将时间恢复默认
    // 开始时间
    ST = HandDefaultTime?.startDateValueOf
    // 结束时间
    ET = HandDefaultTime?.endDateValueOf
    JumpToHereInfo = false
    setGiveParams({
      ...GiveParams,
      pageNum: 1,
      timeArr: HandDefaultTime?.TotalTime,
      startDate: HandDefaultTime?.startDateValueOf,
      endDate: HandDefaultTime?.endDateValueOf,
      deviceSn: undefined,
      operator: undefined,
      skuName: undefined,
      shopId: undefined,
      id: undefined,
      chooseResultType: undefined,
    })
    setTimeout(getGoodsWeighDetail, 0)
  }

  return (
    <div className={styles.newweightrecord_page}>
      {/* 查询条件，查询，重置 */}
      <Card>
        <div className={styles.weightrecord_page_search_record}>
          <div className={styles.weightrecord_search_body_record}>
            <div className={styles.weightrecord_search_body_child}>
              <span>日期：</span>
              <PubRangePicker
                value={GiveParams?.timeArr}
                disDate={30}
                onChange={(val) => {
                  setGiveParams({
                    ...GiveParams,
                    timeArr: val.TotalTime,
                    startDate: val.startDateValueOf,
                    endDate: val.endDateValueOf,
                  })
                }}
              />
            </div>
            <div className={styles.newweightrecord_search_body_child}>
              <span>门店/设备：</span>
              <Cascader
                getPopupContainer={(triggerNode) => triggerNode?.parentNode}
                showSearch
                style={{ width: 220 }}
                changeOnSelect
                options={shopList}
                placeholder="请选择门店/设备"
                value={
                  GiveParams.shopId && GiveParams.deviceSn
                    ? [GiveParams.shopId, GiveParams.deviceSn]
                    : GiveParams.shopId
                    ? [GiveParams.shopId]
                    : []
                }
                onSearch={(e) => {
                  if (e.length >= 2) {
                    getShopTreeList(e)
                  }
                }}
                onChange={(e) => {
                  if (e) {
                    setGiveParams({
                      ...GiveParams,
                      shopId: e[0] ? e[0] : "",
                      deviceSn: e[1] ? e[1] : "",
                    })
                  } else {
                    setGiveParams({
                      ...GiveParams,
                      shopId: undefined,
                      deviceSn: undefined,
                    })
                  }
                }}
              />
            </div>
            <div className={styles.newweightrecord_search_body_child}>
              <span>商品PLU：</span>
              <PubInput
                onChange={(val) => {
                  setGiveParams({ ...GiveParams, skuName: val })
                }}
                placeholder="商品名称/PLU"
                value={GiveParams.skuName}
              />
            </div>
            <div className={styles.newweightrecord_search_body_child}>
              <span>员工工号：</span>
              <PubInput
                onChange={(val) => {
                  setGiveParams({ ...GiveParams, operator: val })
                }}
                placeholder="员工工号"
                value={GiveParams.operator}
              />
            </div>
            <div className={styles.newweightrecord_search_body_child}>
              <span>称重类型：</span>
              <Select
                placeholder="选择称重类型"
                style={{ width: 200 }}
                showSearch={false}
                allowClear
                value={GiveParams.chooseResultType}
                onChange={(e) =>
                  setGiveParams({
                    ...GiveParams,
                    chooseResultType: e,
                  })
                }
              >
                {/* <Option key="0" value="0">
                  无后续操作
                </Option>
                <Option key="1" value="1">
                  自动打印
                </Option> */}
                <Option key="2" value="2">
                  识别结果点选
                </Option>
                <Option key="3" value="3">
                  快捷键点选
                </Option>
                <Option key="4" value="4">
                  搜索中选择
                </Option>
                {/* <Option key="5" value="5">
                  重新识别
                </Option>
                <Option key="6" value="6">
                  按称键盘
                </Option>
                <Option key="8" value="8">
                  直打标签
                </Option>
                <Option key="9" value="9">
                  学习模式
                </Option>
                <Option key="10" value="10">
                  临时商品
                </Option> */}
              </Select>
            </div>
          </div>

          <div className={styles.newweightrecord_search_button_record}>
            <div>
              <Button type="primary" onClick={() => handSearch()}>
                查询
              </Button>
              <Button onClick={() => handClear()}>重置</Button>
            </div>
          </div>
        </div>
      </Card>
      {/* <div className={styles.}newweightrecord_page_body"> */}
      {/* 表格数据 */}
      <Card style={{ marginTop: "16px" }}>
        {/* 导出 */}
        {isShowBut ? (
          <Button type="primary" onClick={getExport}>
            导出数据
          </Button>
        ) : null}

        <Table
          style={{ marginTop: "20px" }}
          dataSource={dataList}
          columns={columns}
          pagination={onTableChange()}
          rowKey="id"
          loading={loading}
          scroll={{
            x: "max-content",
            y: isShowBut ? `${WinHeight - 470}px` : `${WinHeight - 452}px`,
          }}
        />
      </Card>
    </div>
  )
}

export default Index
