import PropTypes from "prop-types"
import React, { Component } from "react"

export class Index extends Component {
  static propTypes = {}

  render() {
    return <div>Index</div>
  }
}

export default Index
