import React, { useEffect, useState, useRef } from "react"
import {
  Button,
  Tooltip,
  Card,
  Switch,
  DatePicker,
  Input,
  message,
  Select,
  Radio,
  TimePicker,
  Badge,
  Tag,
} from "antd"
import { useHistory } from "react-router-dom"
import {
  QuestionCircleOutlined,
  PlusSquareOutlined,
  MinusSquareOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons"
import dayjs from "dayjs"
import * as StoreSever from "../../../services/storemanage"
import * as SystemsetSever from "../../../services/systemset"
import PubInput from "../../../../components/PubInput"
import styles from "./Index.module.css"

const { Option } = Select
const { RangePicker } = DatePicker
const dateFormat = "YYYYMMDD"

function Index() {
  const [loading, setLoading] = useState(false)
  const [shopList, setShopList] = useState([]) // 门店列表
  const [goodsRule, setGoodsRule] = useState([]) // 商品图片库icon列表
  const [devicelist, setDevicelist] = useState([]) // 设备列表
  const [keyCode, setKeyCode] = useState("") // 白名单查询

  const [ParamsInfo, setParamsInfo] = useState({
    name: undefined, // 规则名称
    shopId: undefined, // 适用门店
    isAllGoods: 0, // 选择商品
    skuList: [], // 商品plu
    skuWhiteList: [], // 商品白名单
    priceAbnormalIsOn: 0, // 改价异常
    weightIsOn: 0, // 称重及报警
    priceRule: {
      amountSettingTotalPrice: undefined,
      amountSettingUnitPrice: undefined,
      percentSettingTotalPrice: undefined,
      percentSettingUnitPrice: undefined,
    },
    nonFirstPriceRule: {
      amountSettingPrice: undefined, //改价金额绝对值单价
      percentSettingPrice: undefined, // 改价幅度百分比单价
    },
    timeRule: [{ startTime: "", endTime: "" }],
    deviceKeyCode: undefined, // 白名单查询
    deviceSnList: [], // 选中的设备列表
    searchClickIsOn: 0, // 通过搜索选择：低于识别商品价格
    simpleNotFirstIsOn: 0, //监控不点选首位推荐
    // suitRuleType: 1, // 1:单门店适用的称重规则,2:连锁多门店适用的称重规则
  })

  // REF,动态值，影响表格接口刷新
  const refInfo = useRef({
    ParamsInfoRef: {}, //点击查询后的条件值
  })

  useEffect(() => {
    refInfo.current = {
      ParamsInfoRef: ParamsInfo,
    }
  }, [ParamsInfo])

  /**
   * 初始化
   */
  useEffect(() => {
    // 门店列表
    getShopList()
    // 设备列表
    // getDeviceList()
    //请求商品图片库icon列表接口
    // getIconList()
    // //请求称重规则列表接口
    // getPreventionList()
  }, [])

  const columns = [
    {
      title: "设备激活码",
      dataIndex: "cdKeyCode",

      key: "cdKeyCode",
    },
    // {
    //   title: "设备SN",
    //   dataIndex: "deviceSn",
    //
    //   key: "deviceSn",
    // },
    {
      title: "设备秤号",
      dataIndex: "scaleNo",

      key: "scaleNo",
    },
  ]

  /**
   * 获取门店列表
   */
  const getShopList = async (index) => {
    try {
      const params = {
        pageNum: 1,
        pageSize: 1000,
        code: "",
      }
      const { code, msg, data } = await StoreSever.Shop_List(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      setShopList(data?.list || [])
      // if (data && data?.list.length > 0) {
      //   data?.list.unshift({
      //     shopName: "全部",
      //     shopId: "0",
      //   })
      //   setShopList(data?.list)
      // } else {
      //   setShopList([])
      // }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 商品图片库icon列表接口
   */
  const getIconList = async () => {
    try {
      const params = {
        pageNum: 1,
        pageSize: 500,
      }
      const { code, msg, data } = await SystemsetSever.IconList(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data && data?.list.length > 0) {
        data.list.map((v) => {
          v.showTitle = v?.skuCode + "-" + v.skuName
        })
        setGoodsRule(data.list)
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 获取门店下商品信息
   */
  const getShopSkuList = async (shopId) => {
    try {
      const params = {
        pageNum: 1,
        pageSize: 999999,
        priceUnit: 0,
        shopId,
      }
      const { code, msg, data } = await SystemsetSever.Shop_Sku_List(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data && data?.list.length > 0) {
        data.list.map((v) => {
          v.showTitle = v?.skuCode + "-" + v.skuName
        })
        setGoodsRule(data.list)
      }
    } catch (error) {
      console.log(error)
    }
  }
  /**
   * 商品图片库icon列表接口 查询
   */
  const getIconListSearch = async (value) => {
    try {
      const params = {
        code: value,
        pageNum: 1,
        pageSize: 500,
      }
      const { code, msg, data } = await SystemsetSever.IconList(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data && data?.list.length > 0) {
        let NewArr = goodsRule
        var obj = {}
        var newarr = []
        NewArr = data?.list.concat(NewArr)
        for (var i = 0; i < NewArr.length; i++) {
          NewArr[i].showTitle = NewArr[i]?.skuCode + "-" + NewArr[i]?.skuName
          if (!obj[NewArr[i].skuCode] && !obj[NewArr[i].skuName]) {
            newarr.push(NewArr[i])
            obj[NewArr[i].skuCode] = true
          }
        }
        setGoodsRule(newarr)
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 获取设备列表默认
   */
  const getDeviceList = async (shopId) => {
    try {
      const params = {
        pageNum: 1,
        pageSize: 1000,
        keyCode: "",
        productLineId: "",
        productLineTypeId: "",
        shopId,
        scaleNo: "",
        keyCodeOrScaleNo: ParamsInfo?.deviceKeyCode
          ? ParamsInfo?.deviceKeyCode
          : "",
        deviceSnList: [],
      }
      const { code, msg, data } = await StoreSever.Devices_ListOne(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data && data?.list.length > 0) {
        data?.list.map((v) => {
          v.showInfo = v?.cdKeyCode + (v?.scaleNo ? " - " + v?.scaleNo : "")
        })
        setDevicelist(data?.list)
      } else {
        setDevicelist([])
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 保存
   */
  const onSaveSet = async () => {
    try {
      ParamsInfo.isOpen = 0
      if (!ParamsInfo?.name) {
        message.warning("请输入规则名称")
        return
      }
      if (!ParamsInfo?.shopId) {
        message.warning("请选择适用门店")
        return
      }
      if (!ParamsInfo?.isAllGoods && ParamsInfo?.isAllGoods != 0) {
        message.warning("请选择适用商品")
        return
      }
      if (ParamsInfo?.skuList?.length == 0 && ParamsInfo?.isAllGoods == 0) {
        message.warning("请选择商品plu")
        return
      }
      if (
        !ParamsInfo?.priceRule?.amountSettingTotalPrice &&
        !ParamsInfo?.priceRule?.amountSettingUnitPrice &&
        !ParamsInfo?.priceRule?.percentSettingTotalPrice &&
        !ParamsInfo?.priceRule?.percentSettingUnitPrice &&
        !ParamsInfo?.nonFirstPriceRule?.amountSettingPrice &&
        !ParamsInfo?.nonFirstPriceRule?.percentSettingPrice &&
        ParamsInfo?.weightIsOn == 0 &&
        ParamsInfo?.searchClickIsOn == 0 &&
        ParamsInfo?.simpleNotFirstIsOn == 0
      ) {
        message.warning("至少设置一个规则")
        return
      }
      const NewTimeRule = ParamsInfo.timeRule
      for (let index = 0; index < NewTimeRule.length; index++) {
        if (!NewTimeRule[index].startTime) {
          message.warning("请将开始时间补充完整")
          return
        }
        if (!NewTimeRule[index].endTime) {
          message.warning("请将结束时间补充完整")
          return
        }
      }
      setLoading(true)
      const { code, data, msg } = await SystemsetSever.AddNewPrevention(
        ParamsInfo
      )
      if (code != 200) {
        message.error(msg)
        setLoading(false)
        return
      }
      if (data) {
        setLoading(false)
        message.success("新增规则成功")
        window.history.back(-1)
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
      // message.error("保存失败")
    }
  }

  // 新建称重规则
  return (
    <Card>
      <div className={styles.add_loss_body}>
        {/* 规则名称 */}
        <div className={styles.add_loss_body_child}>
          <span className={styles.add_loss_body_child_span}>*</span>
          规则名称：
          <PubInput
            onChange={(val) => {
              setParamsInfo({
                ...ParamsInfo,
                name: val,
              })
            }}
            placeholder="规则名称"
            value={ParamsInfo.name}
            width={500}
            showCount={true}
            max={100}
          />
        </div>

        {/* 适用门店 */}
        <div className={styles.add_loss_body_child}>
          <span className={styles.add_loss_body_child_span}>*</span>
          适用门店：
          <Select
            // mode="multiple"
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            placeholder="请选择适用门店"
            style={{ width: 500 }}
            allowClear
            value={ParamsInfo.shopId}
            showSearch
            filterOption={(i, o) => o.props.children.includes(i)}
            onChange={(e) => {
              setParamsInfo({
                ...ParamsInfo,
                shopId: e || undefined,
                skuList: [],
                deviceSnList: [],
              })
              if (e) {
                getShopSkuList(e)
                getDeviceList(e)
              } else {
                setGoodsRule([])
                setDevicelist([])
              }
            }}
          >
            {shopList.map((item, index) => {
              return (
                <Option value={item?.shopId} key={index}>
                  {item?.shopName}
                </Option>
              )
            })}
          </Select>
        </div>

        {/* 适用商品 */}
        <div className={styles.add_loss_body_child}>
          <span className={styles.add_loss_body_child_span}>*</span>
          适用商品：
          <Radio.Group
            onChange={(e) => {
              setParamsInfo({
                ...ParamsInfo,
                isAllGoods: e.target.value,
              })
            }}
            value={ParamsInfo.isAllGoods}
          >
            <Radio value={0}>部分商品</Radio>
            <Radio value={1}>全部商品</Radio>
          </Radio.Group>
        </div>

        {/* 商品plu */}
        {ParamsInfo.isAllGoods == 0 ? (
          <div className={styles.add_loss_body_child}>
            <span className={styles.add_loss_body_child_span}>*</span>
            商品plu：
            <Select
              maxTagCount={4}
              mode="multiple"
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              filterOption={(i, o) => o.props.children.includes(i)}
              placeholder="请选择自定义商品plu"
              style={{ width: 500 }}
              allowClear
              value={ParamsInfo.skuList}
              showSearch
              onSearch={(e) => {
                if (e.length > 0) {
                  getIconListSearch(e)
                }
              }}
              onChange={(e) => {
                setParamsInfo({
                  ...ParamsInfo,
                  skuList: e,
                })
              }}
            >
              {goodsRule.map((item, index) => {
                return (
                  <Option value={item?.skuCode} key={index}>
                    {item?.showTitle}
                  </Option>
                )
              })}
            </Select>
          </div>
        ) : (
          <div
            className={styles.add_loss_body_child}
            style={{ marginLeft: "10px" }}
          >
            {/* <span className={styles.add_loss_body_child_span}>*</span> */}
            不包括商品：
            <Select
              maxTagCount={4}
              mode="multiple"
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              filterOption={(i, o) => o.props.children.includes(i)}
              placeholder="请选择不包括商品，支持多选"
              style={{ width: 500 }}
              allowClear
              value={ParamsInfo.skuWhiteList}
              showSearch
              onSearch={(e) => {
                if (e.length > 0) {
                  getIconListSearch(e)
                }
              }}
              onChange={(e) => {
                setParamsInfo({
                  ...ParamsInfo,
                  skuWhiteList: e,
                })
              }}
            >
              {goodsRule.map((item, index) => {
                return (
                  <Option value={item?.skuCode} key={index}>
                    {item?.showTitle}
                  </Option>
                )
              })}
            </Select>
          </div>
        )}

        {/* 设置称重规则 */}
        <div className={styles.add_loss_body_child_laji}>
          <div className={styles.add_loss_body_child_laji_title}>
            <span className={styles.add_loss_body_child_span}>*</span>
            设置规则：
          </div>
          {/* 监控改价出售 */}
          <div>
            <div className={styles.add_loss_body_child_laji_child}>
              <div>
                <Badge
                  status={
                    ParamsInfo?.priceRule?.amountSettingTotalPrice ||
                    ParamsInfo?.priceRule?.amountSettingUnitPrice ||
                    ParamsInfo?.priceRule?.percentSettingTotalPrice ||
                    ParamsInfo?.priceRule?.percentSettingUnitPrice
                      ? "success"
                      : "default"
                  }
                />
                <span style={{ margin: "0 5px" }}>监控改价出售</span>
              </div>
              <Tooltip
                title="可设置监控改价行为：如商品改价幅度超过设定的“金额或者百分比“，则生成异常记录"
                placement="right"
              >
                <QuestionCircleOutlined
                  style={{ cursor: "pointer", color: "#2EB998" }}
                />
              </Tooltip>
            </div>
            {/* 单价改价幅度超过 */}
            <div
              className={styles.add_loss_body_child_laji_child}
              style={{ marginLeft: "20px" }}
            >
              <div style={{ color: "#666666" }}>单价改价幅度超过</div>
              <Input
                addonAfter="元"
                style={{ width: "120px", marginLeft: "20px" }}
                placeholder="请设置"
                value={ParamsInfo?.priceRule?.amountSettingUnitPrice}
                onChange={(e) => {
                  let NewPriceRule = ParamsInfo.priceRule
                  NewPriceRule = {
                    ...ParamsInfo.priceRule,
                    amountSettingUnitPrice:
                      e.target.value
                        .toString()
                        .replace(/[^\d^\.]+/g, "") //  第二步：把不是数字，不是小数点的过滤掉
                        .replace(/^0+(\d)/, "$1") // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
                        .replace(/^\./, "0.") // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
                        .match(/^\d*(\.?\d{0,2})/g)[0] || "", // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，而且小数点后面只能有0到2位小数
                    // .replace(/^(0+)|[^\d]+/g, ""),
                  }
                  setParamsInfo({
                    ...ParamsInfo,
                    priceRule: NewPriceRule,
                  })
                }}
              />
              <span style={{ padding: "0 20px" }}>且</span>
              <Input
                addonAfter="%"
                style={{ width: "120px" }}
                placeholder="请设置"
                value={ParamsInfo?.priceRule?.percentSettingUnitPrice}
                onChange={(e) => {
                  let NewPriceRule = ParamsInfo.priceRule
                  NewPriceRule = {
                    ...ParamsInfo.priceRule,
                    percentSettingUnitPrice:
                      e.target.value
                        .toString()
                        .replace(/[^\d^\.]+/g, "") //  第二步：把不是数字，不是小数点的过滤掉
                        .replace(/^0+(\d)/, "$1") // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
                        .replace(/^\./, "0.") // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
                        .match(/^\d*(\.?\d{0,2})/g)[0] || "", // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，而且小数点后面只能有0到2位小数
                    // .replace(/^(0+)|[^\d]+/g, ""),
                  }
                  setParamsInfo({
                    ...ParamsInfo,
                    priceRule: NewPriceRule,
                  })
                }}
              />
            </div>
            {/* // */}
            {/* 总价改价幅度超过 */}
            <div
              className={styles.add_loss_body_child_laji_child}
              style={{ marginLeft: "20px" }}
            >
              <div style={{ color: "#666666" }}>总价改价幅度超过</div>
              <Input
                addonAfter="元"
                style={{ width: "120px", marginLeft: "20px" }}
                placeholder="请设置"
                value={ParamsInfo?.priceRule?.amountSettingTotalPrice}
                onChange={(e) => {
                  let NewPriceRule = ParamsInfo.priceRule
                  NewPriceRule = {
                    ...ParamsInfo.priceRule,
                    amountSettingTotalPrice:
                      e.target.value
                        .toString()
                        .replace(/[^\d^\.]+/g, "") //  第二步：把不是数字，不是小数点的过滤掉
                        .replace(/^0+(\d)/, "$1") // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
                        .replace(/^\./, "0.") // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
                        .match(/^\d*(\.?\d{0,2})/g)[0] || "", // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，而且小数点后面只能有0到2位小数
                    // .replace(/^(0+)|[^\d]+/g, ""),
                  }
                  setParamsInfo({
                    ...ParamsInfo,
                    priceRule: NewPriceRule,
                  })
                }}
              />
              <span style={{ padding: "0 20px" }}>且</span>
              <Input
                addonAfter="%"
                style={{ width: "120px" }}
                placeholder="请设置"
                value={ParamsInfo?.priceRule?.percentSettingTotalPrice}
                onChange={(e) => {
                  let NewPriceRule = ParamsInfo.priceRule
                  NewPriceRule = {
                    ...ParamsInfo.priceRule,
                    percentSettingTotalPrice:
                      e.target.value
                        .toString()
                        .replace(/[^\d^\.]+/g, "") //  第二步：把不是数字，不是小数点的过滤掉
                        .replace(/^0+(\d)/, "$1") // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
                        .replace(/^\./, "0.") // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
                        .match(/^\d*(\.?\d{0,2})/g)[0] || "", // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，而且小数点后面只能有0到2位小数
                    // .replace(/^(0+)|[^\d]+/g, ""),
                  }
                  setParamsInfo({
                    ...ParamsInfo,
                    priceRule: NewPriceRule,
                  })
                }}
              />
              {/* <Tag
                color="#FCF6EC"
                height={20}
                className={styles.tag_body}
                icon={
                  <ExclamationCircleOutlined style={{ color: "#E6A23C" }} />
                }
              >
                <span style={{ color: "#E6A23C" }}>
                  前端设备改价超过单价/总价的百分比，报出异常
                </span>
              </Tag> */}
            </div>
            {/* // */}
            {/* Select切换 */}
            {/* <div className={styles.add_loss_body_child_laji_child}>
              <Select
                placeholder="请选择选择类型"
                style={{ width: 300 }}
                value={ParamsInfo.suitRuleType}
                onChange={(e) => {
                  setParamsInfo({
                    ...ParamsInfo,
                    suitRuleType: e,
                    simpleNotFirstIsOn: 0,
                    searchClickIsOn: 0,
                    nonFirstPriceRule: {
                      amountSettingPrice: undefined, //改价金额绝对值单价
                      percentSettingPrice: undefined, // 改价幅度百分比单价
                    },
                  })
                }}
              >
                <Option value={1}>单门店适用的称重规则</Option>
                <Option value={2}>连锁多门店适用的称重规则</Option>
              </Select>
            </div> */}
            {/* // */}
            {/* AI监控：商品被低价出售 */}

            <div className={styles.add_loss_body_child_laji_child}>
              <Badge
                status={
                  ParamsInfo?.searchClickIsOn == 1 ||
                  ParamsInfo?.nonFirstPriceRule?.amountSettingPrice ||
                  ParamsInfo?.nonFirstPriceRule?.percentSettingPrice
                    ? "success"
                    : "default"
                }
              />
              <span style={{ margin: "0 5px" }}>AI监控：商品被低价出售</span>
              <Tooltip
                title="
                可设置监控AI监控：商品被低价出售行为：如点选的商品价格低于设备识别结果最高价，则生成异常记录"
                placement="right"
              >
                <QuestionCircleOutlined
                  style={{ cursor: "pointer", color: "#2EB998" }}
                />
              </Tooltip>
              <Tag
                color="#FCF6EC"
                height={20}
                className={styles.tag_body}
                icon={
                  <ExclamationCircleOutlined style={{ color: "#E6A23C" }} />
                }
              >
                <span style={{ color: "#E6A23C" }}>
                  注：此条规则对设备版本要求为Android4.4.4.3-window4.5.0.0以上
                </span>
              </Tag>
            </div>
            {/* 不点选首位商品 */}
            {/* // */}

            {/*  通过搜索选择：低于识别商品价格 */}
            <div
              className={styles.add_loss_body_child_laji_child}
              style={{ marginLeft: "20px" }}
            >
              <div style={{ color: "#666666", marginRight: "20px" }}>
                通过搜索选择：低于识别商品价格
              </div>
              <Switch
                checked={ParamsInfo?.searchClickIsOn == 1 ? true : false}
                onChange={(checked) => {
                  setParamsInfo({
                    ...ParamsInfo,
                    searchClickIsOn: checked ? 1 : "0",
                  })
                }}
              />
              {/* <Tag
                color="#FCF6EC"
                height={20}
                className={styles.tag_body}
                icon={
                  <ExclamationCircleOutlined style={{ color: "#E6A23C" }} />
                }
              >
                <span style={{ color: "#E6A23C" }}>
                  前端设备选择商品低于识别结果的绝对值/百分比，报出异常
                </span>
              </Tag> */}
            </div>
            <div
              className={styles.add_loss_body_child_laji_child}
              style={{ marginLeft: "20px" }}
            >
              <div style={{ color: "#666666" }}>
                通过列表点选：低于识别商品价格
              </div>
              <Input
                addonAfter="元"
                style={{ width: "120px", marginLeft: "20px" }}
                placeholder="请设置"
                value={ParamsInfo?.nonFirstPriceRule?.amountSettingPrice}
                onChange={(e) => {
                  let NewNonFirstPriceRule = ParamsInfo.nonFirstPriceRule
                  NewNonFirstPriceRule = {
                    ...ParamsInfo.nonFirstPriceRule,
                    amountSettingPrice:
                      e.target.value
                        .toString()
                        .replace(/[^\d^\.]+/g, "") //  第二步：把不是数字，不是小数点的过滤掉
                        .replace(/^0+(\d)/, "$1") // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
                        .replace(/^\./, "0.") // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
                        .match(/^\d*(\.?\d{0,2})/g)[0] || "", // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，而且小数点后面只能有0到2位小数
                    // .replace(/^(0+)|[^\d]+/g, ""),
                  }
                  setParamsInfo({
                    ...ParamsInfo,
                    nonFirstPriceRule: NewNonFirstPriceRule,
                  })
                }}
              />
              <span style={{ padding: "0 20px" }}>或</span>
              <Input
                addonAfter="%"
                style={{ width: "120px" }}
                placeholder="请设置"
                value={ParamsInfo?.nonFirstPriceRule?.percentSettingPrice}
                onChange={(e) => {
                  let NewNonFirstPriceRule = ParamsInfo.nonFirstPriceRule
                  NewNonFirstPriceRule = {
                    ...ParamsInfo.nonFirstPriceRule,
                    percentSettingPrice:
                      e.target.value
                        .toString()
                        .replace(/[^\d^\.]+/g, "") //  第二步：把不是数字，不是小数点的过滤掉
                        .replace(/^0+(\d)/, "$1") // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
                        .replace(/^\./, "0.") // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
                        .match(/^\d*(\.?\d{0,2})/g)[0] || "", // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，而且小数点后面只能有0到2位小数
                    // .replace(/^(0+)|[^\d]+/g, ""),
                  }
                  setParamsInfo({
                    ...ParamsInfo,
                    nonFirstPriceRule: NewNonFirstPriceRule,
                  })
                }}
              />
              {/* <Tag
                color="#FCF6EC"
                height={20}
                className={styles.tag_body}
                icon={
                  <ExclamationCircleOutlined style={{ color: "#E6A23C" }} />
                }
              >
                <span style={{ color: "#E6A23C" }}>
                  前端设备选择商品低于识别结果的绝对值/百分比，报出异常
                </span>
              </Tag> */}
            </div>

            {/* // */}
            {/* 监控指定商品被点选 */}
            <div className={styles.add_loss_body_child_laji_child}>
              <Badge
                status={ParamsInfo?.weightIsOn == 1 ? "success" : "default"}
              />
              <span style={{ margin: "0 5px" }}>监控指定商品被点选</span>
              <Switch
                style={{ margin: "0 10px" }}
                checked={ParamsInfo?.weightIsOn == 1 ? true : false}
                onChange={(checked) => {
                  setParamsInfo({
                    ...ParamsInfo,
                    weightIsOn: checked ? 1 : "0",
                  })
                }}
              />
              <Tooltip
                title="可设置监控重点商品：如该商品发生了点选并输出条码的行为，则生成异常记录"
                placement="right"
              >
                <QuestionCircleOutlined
                  style={{ cursor: "pointer", color: "#2EB998" }}
                />
              </Tooltip>
            </div>
            {/* // */}
            {/* 监控不点选首位推荐 */}
            <div className={styles.add_loss_body_child_laji_child}>
              <Badge
                status={
                  ParamsInfo?.simpleNotFirstIsOn == 1 ? "success" : "default"
                }
              />
              <span style={{ margin: "0 5px" }}>监控不点选首位推荐</span>
              <Switch
                style={{ margin: "0 10px" }}
                checked={ParamsInfo?.simpleNotFirstIsOn == 1 ? true : false}
                onChange={(checked) => {
                  setParamsInfo({
                    ...ParamsInfo,
                    simpleNotFirstIsOn: checked ? 1 : "0",
                  })
                }}
              />
              <Tooltip
                title="可设置监控监控不点选首位推荐行为：如点选了非首位商品/低于首位商品价格，则生成异常记录"
                placement="right"
              >
                <QuestionCircleOutlined
                  style={{ cursor: "pointer", color: "#2EB998" }}
                />
              </Tooltip>
              {/* <Tag
                color="#FCF6EC"
                height={20}
                className={styles.tag_body}
                icon={
                  <ExclamationCircleOutlined style={{ color: "#E6A23C" }} />
                }
              >
                <span style={{ color: "#E6A23C" }}>
                  前端设备选择非首位商品/价格低于首位，认为损耗异常
                </span>
              </Tag> */}
            </div>
            {/* // */}
          </div>
        </div>
        {/* 生效时间 */}
        <div className={styles.add_loss_body_child_laji}>
          <div className={styles.add_loss_body_child_laji_title}>
            <span className={styles.add_loss_body_child_span}>*</span>
            监控时间：
          </div>
          <div
            className={styles.add_loss_body_child_laji_child}
            style={{ flexDirection: "column", marginTop: "-5px" }}
          >
            {ParamsInfo.timeRule.map((v, i) => {
              return (
                <div
                  key={i}
                  className={styles.add_loss_body_child_time_child}
                  style={{
                    marginBottom: `${
                      i == ParamsInfo.timeRule.length - 1 ? 0 : 20
                    }px`,
                  }}
                >
                  <TimePicker
                    style={{ width: "200px" }}
                    onChange={(e) => {
                      // 判断开始时间要小于结束时间
                      const { endTime } = ParamsInfo.timeRule[i]
                      let NewEndTime = undefined
                      let NewStartTime = undefined
                      if (endTime) {
                        NewEndTime = 1 + "" + endTime.replace(":", "")
                      }
                      if (e) {
                        NewStartTime =
                          1 + "" + dayjs(e).format("HH:mm").replace(":", "")
                      }
                      if (
                        NewEndTime &&
                        NewStartTime &&
                        NewStartTime >= NewEndTime
                      ) {
                        message.error("开始时间不可以大于结束时间")
                        return
                      }
                      //
                      //判断时间区间不能重合
                      for (let z = 0; z < ParamsInfo.timeRule.length; z++) {
                        if (i != z) {
                          let NewListEndTime = undefined
                          let NewListStartTime = undefined
                          if (
                            ParamsInfo.timeRule[z].endTime ||
                            ParamsInfo.timeRule[z].startTime
                          ) {
                            NewListEndTime =
                              1 +
                              "" +
                              ParamsInfo.timeRule[z].endTime.replace(":", "")
                            NewListStartTime =
                              1 +
                              "" +
                              ParamsInfo.timeRule[z].startTime.replace(":", "")

                            // 只有开始时间存在的情况
                            if (NewStartTime && !NewEndTime) {
                              if (
                                NewStartTime <= NewListEndTime &&
                                NewStartTime >= NewListStartTime
                              ) {
                                message.error("时间区间不可重复")
                                return
                              }
                            }

                            // 开始时间和结束时间都存在的同时
                            if (NewStartTime && NewEndTime) {
                              if (
                                (NewStartTime < NewListStartTime &&
                                  NewEndTime > NewListEndTime) ||
                                (NewEndTime < NewListEndTime &&
                                  NewEndTime > NewListStartTime) ||
                                (NewStartTime < NewListEndTime &&
                                  NewStartTime > NewListStartTime)
                              ) {
                                message.error("时间区间不可重复")
                                return
                              }
                            }
                          }
                        }
                      }
                      ParamsInfo.timeRule[i].startTime = e
                        ? dayjs(e).format("HH:mm")
                        : null
                      setParamsInfo({
                        ...ParamsInfo,
                      })
                    }}
                    placeholder="开始时间"
                    value={v.startTime ? dayjs(v.startTime, "HH:mm") : null}
                    format={"HH:mm"}
                  />
                  <TimePicker
                    style={{
                      width: "200px",
                      margin: "0 20px",
                    }}
                    onChange={(e) => {
                      // 判断结束时间要大于结开始时间
                      const { startTime } = ParamsInfo.timeRule[i]
                      let NewStartTime = undefined
                      let NewEndTime = undefined
                      if (startTime) {
                        NewStartTime = 1 + "" + startTime.replace(":", "")
                      }
                      if (e) {
                        NewEndTime =
                          1 + "" + dayjs(e).format("HH:mm").replace(":", "")
                      }
                      if (
                        NewStartTime &&
                        NewEndTime &&
                        NewEndTime <= NewStartTime
                      ) {
                        message.error("结束时间不可以小于开始时间")
                        return
                      }
                      //
                      //判断时间区间不能重合
                      for (let z = 0; z < ParamsInfo.timeRule.length; z++) {
                        if (i != z) {
                          let NewListEndTime = undefined
                          let NewListStartTime = undefined
                          if (
                            ParamsInfo.timeRule[z].endTime &&
                            ParamsInfo.timeRule[z].startTime
                          ) {
                            NewListEndTime =
                              1 +
                              "" +
                              ParamsInfo.timeRule[z].endTime.replace(":", "")
                            NewListStartTime =
                              1 +
                              "" +
                              ParamsInfo.timeRule[z].startTime.replace(":", "")

                            // 只有结束时间存在的情况
                            if (!NewStartTime && NewEndTime) {
                              if (
                                NewEndTime < NewListEndTime &&
                                NewEndTime > NewListStartTime
                              ) {
                                message.error("时间区间不可重复")
                                return
                              }
                            }

                            // 开始时间和结束时间都存在的同时
                            if (NewStartTime && NewEndTime) {
                              if (
                                (NewStartTime < NewListStartTime &&
                                  NewEndTime > NewListEndTime) ||
                                (NewEndTime < NewListEndTime &&
                                  NewEndTime > NewListStartTime) ||
                                (NewStartTime < NewListEndTime &&
                                  NewStartTime > NewListStartTime)
                              ) {
                                message.error("时间区间不可重复")
                                return
                              }
                            }
                          }
                        }
                      }
                      ParamsInfo.timeRule[i].endTime = e
                        ? dayjs(e).format("HH:mm")
                        : null
                      setParamsInfo({
                        ...ParamsInfo,
                      })
                    }}
                    value={v.endTime ? dayjs(v.endTime, "HH:mm") : null}
                    placeholder="结束时间"
                    format={"HH:mm"}
                  />
                  {i == ParamsInfo.timeRule.length - 1 ? (
                    <PlusSquareOutlined
                      style={{
                        fontSize: "25px",
                        color: "#2EB998",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        const NewTimeRule = ParamsInfo.timeRule
                        NewTimeRule.push({ startTime: "", endTime: "" })
                        if (NewTimeRule.length > 4) {
                          message.warning("时间段最多支持设置4个")
                          NewTimeRule.pop()
                          return
                        }
                        setParamsInfo({
                          ...ParamsInfo,
                          timeRule: NewTimeRule,
                        })
                      }}
                    />
                  ) : (
                    <MinusSquareOutlined
                      style={{
                        fontSize: "25px",
                        color: "#F56C6C",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        const NewTimeRule = ParamsInfo.timeRule
                        NewTimeRule.splice(i, 1)
                        setParamsInfo({
                          ...ParamsInfo,
                          timeRule: NewTimeRule,
                        })
                      }}
                    />
                  )}
                </div>
              )
            })}
          </div>
        </div>

        {/* 不生效设备 */}
        <div className={styles.add_loss_body_child}>
          <div style={{ marginLeft: "5px" }}>不生效设备：</div>
          <Select
            maxTagCount={4}
            mode="multiple"
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            placeholder="请选择不生效设备"
            style={{ width: 500 }}
            allowClear
            value={ParamsInfo.deviceSnList}
            showSearch
            filterOption={(i, o) => o.props.children.includes(i)}
            onChange={(e) => {
              setParamsInfo({
                ...ParamsInfo,
                deviceSnList: e || [],
              })
            }}
          >
            {devicelist.map((item, index) => {
              return (
                <Option value={item?.deviceSn} key={index}>
                  {item?.showInfo}
                </Option>
              )
            })}
          </Select>
        </div>

        {/* 白名单 */}
        {/* <div className={styles.}add_loss_body_child_laji">
        <div
          className={styles.}add_loss_body_child_laji_title"
          style={{ minWidth: "100px", marginLeft: "10px" }}
        >
          设备白名单：
        </div>
        <div className={styles.}add_loss_body_child_laji_body">
          <div className={styles.}add_loss_body_child_laji_child">
            <Input
              placeholder="请输入设备激活码/设备秤号"
              style={{ width: "200px", height: "35px" }}
              value={ParamsInfo?.deviceKeyCode || undefined}
              onChange={(e) => {
                setParamsInfo({
                  ...ParamsInfo,
                  deviceKeyCode: e.target.value || "",
                })
              }}
            />
            <Button
              type="primary"
              onClick={() => getDeviceList()}
              style={{ marginLeft: "20px" }}
            >
             查询
            </Button>
          </div>
          <div className={styles.}add_loss_body_child_laji_child">
            <Table
              style={{ width: "100%" }}
              dataSource={devicelist}
              columns={columns}
              rowKey="deviceSn"
              pagination={false}
              bordered
              rowSelection={{
                selectedRowKeys: ParamsInfo.deviceSnList,
                onChange: (e) => {
                  setParamsInfo({
                    ...ParamsInfo,
                    deviceSnList: e,
                  })
                },
              }}
            />
          </div>
        </div>
      </div> */}
        {/* </Card> */}

        {/* 确定取消 */}
        <div className={styles.add_loss_body_child}>
          <Button type="primary" onClick={onSaveSet} loading={loading}>
            确定
          </Button>
          <Button
            style={{ marginLeft: "20px" }}
            onClick={() => {
              window.history.back(-1)
            }}
          >
            取消
          </Button>
        </div>
        {/* </Card> */}
      </div>
    </Card>
  )
}

export default Index
