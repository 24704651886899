import React, { useEffect, useState, useRef } from "react"
import {
  Card,
  Button,
  Popconfirm,
  Table,
  Switch,
  message,
  Select,
  DatePicker,
  Tooltip,
} from "antd"
import * as LossSever from "../../services/systemset"
import { useHistory } from "react-router-dom"
import { SearchOutlined, SyncOutlined, PlusOutlined } from "@ant-design/icons"
import dayjs from "dayjs"
import styles from "./Index.module.css"
import PubInput from "../../../components/PubInput"

const { Option } = Select
const { RangePicker } = DatePicker
const dateFormat = "YYYYMMDD"

function Index(props) {
  let history = useHistory()
  const [lossList, setLossList] = useState([]) // 称重规则列表
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [TimeAll, setTimeAll] = useState([]) //总时间

  const [ParamsInfo, setParamsInfo] = useState({
    pageNum: 1,
    pageSize: 20,
    name: undefined,
    // employeeId: undefined,
    // affiliated: undefined,
    // startDate: undefined, //开始时间
    // endDate: undefined, //结束时间
  })

  // REF,动态值，影响表格接口刷新
  const refInfo = useRef({
    ParamsInfoRef: {}, //点击查询后的条件值
  })

  useEffect(() => {
    refInfo.current = {
      ParamsInfoRef: ParamsInfo,
    }
  }, [ParamsInfo])

  // 初始化
  useEffect(() => {
    setParamsInfo({ ...ParamsInfo, pageNum: 1 })
    //称重规则列表接口，
    setTimeout(getPreventionList, 0)
  }, [])

  /**
   * 称重规则列表
   */
  const getPreventionList = async () => {
    try {
      const { ParamsInfoRef } = refInfo?.current
      // const params = {
      //   pageNum: ParamsInfoRef.pageNum,
      //   pageSize: ParamsInfoRef.pageSize,
      //   employeeId: ParamsInfoRef.employeeId,
      //   affiliated: ParamsInfoRef.affiliated,
      //   startDate: ParamsInfoRef.startDate, //开始时间
      //   endDate: ParamsInfoRef.endDate, //结束时间
      // }
      setLoading(true)
      const { code, data, msg } = await LossSever.PreventionList(ParamsInfoRef)
      if (code != 200 || data === null) {
        message.error(msg)
        setLoading(false)
        return
      }
      setLossList([])

      setLossList(data.list)
      setTotal(data.total)
      setLoading(false)
    } catch (error) {
      message.error("称重规则列表获取失败")
      setLoading(false)
    }
  }

  /**
   * 新建称重规则
   */
  const onJumpAdd = () => {
    history.push("/admin/lossset/addloss")
  }

  /**
   * 编辑称重规则
   */
  const onJumpEdit = (rowInfo) => {
    console.log(rowInfo)
    const Params = JSON.stringify(rowInfo)
    history.push(`/admin/lossset/editloss?id=${rowInfo.id}`)
    // props.history.push({
    //   pathname: `/admin/lossset/editloss?id=${rowInfo.id}`,
    //   state: { Info: Params },
    // })
  }

  /**
   * 查询
   */
  const handlSearch = async () => {
    try {
      //页码为1
      setParamsInfo({ ...ParamsInfo, pageNum: 1 })
      //称重规则列表
      setTimeout(getPreventionList, 0)
    } catch (error) {
      message.error(error)
      return
    }
  }

  /**
   * 重置
   */
  const handClearn = async () => {
    try {
      setParamsInfo({
        pageNum: 1,
        pageSize: 20,
        name: undefined,
        // employeeId: undefined,
        // affiliated: undefined,
        // startDate: undefined, //开始时间
        // endDate: undefined, //结束时间
      })
      setTimeAll([])
      //称重规则列表
      setTimeout(getPreventionList, 0)
    } catch (error) {
      console.log(error)
      return
    }
  }

  /**
   * 开关编辑
   */
  const onEdit = async (id, checked) => {
    try {
      let params = { id: id, isOpen: checked ? 1 : 0 }
      const { code, data, msg } = await LossSever.PreventionSwitch(params)
      if (code != 200 || data === null) {
        message.error(msg)
        return
      }
      message.success(`操作成功`)
      //称重规则列表
      setTimeout(getPreventionList, 0)
    } catch (error) {
      console.log(error)
      return
    }
  }

  /**
   * 删除
   */
  const onDelete = async (rowInfo) => {
    try {
      const { code, data, msg } = await LossSever.DeletePrevention(rowInfo.id)
      if (code != 200 || data === null) {
        message.error(msg)
        return
      }
      message.success(`${rowInfo.name}删除成功`)
      //称重规则列表
      setTimeout(getPreventionList, 0)
    } catch (error) {
      message.error("删除失败")
      return
    }
  }

  //分页
  const onTableChange = () => {
    const paginationProps = {
      showSizeChanger: false,
      showQuickJumper: false,
      showTotal: () => `共${total}条`,
      pageSize: ParamsInfo.pageSize,
      current: ParamsInfo.pageNum,
      total: total,
      onChange: (current) => {
        setParamsInfo({ ...ParamsInfo, pageNum: current })
        // 称重规则列表
        setTimeout(getPreventionList, 0)
      },
    }
    return paginationProps
  }

  const columns = [
    {
      title: "创建时间",
      dataIndex: "createdAt",
      key: "createdAt",
      ellipsis: { showTitle: true },
      width: 200,
    },
    {
      title: "规则名称",
      dataIndex: "name",
      key: "name",
      ellipsis: { showTitle: true },
      width: 200,
    },
    {
      title: "编号",
      dataIndex: "id",
      key: "id",
      ellipsis: { showTitle: true },
      width: 200,
    },
    {
      title: "生效商品数量",
      dataIndex: "skuList",
      key: "skuList",
      width: 150,
      ellipsis: { showTitle: true },
      render: (info, rowInfo) => {
        return <>{rowInfo.isAllGoods == 1 ? "全部" : info?.length || 0}</>
      },
    },
    {
      title: "不包括商品",
      dataIndex: "whiteGoodsNameList",
      ellipsis: { showTitle: true },
      key: "whiteGoodsNameList",
      width: 200,
      render: (info) => {
        return (
          <Tooltip title={info ? info.join("，") : ""}>
            {info ? info.join("，") : ""}
          </Tooltip>
        )
      },
      // render: (info) => {
      //   return (
      //     info &&
      //     info.map((v, i) => {
      //       return (
      //         <span key={i}>
      //           <Tooltip
      //             title={() => {
      //               return info.map((v, i) => {
      //                 return <span key={i}>{v}，</span>
      //               })
      //             }}
      //           >
      //             {v}，
      //           </Tooltip>
      //         </span>
      //       )
      //     })
      //   )
      // },
    },
    {
      title: "适用门店",
      dataIndex: "shopName",
      ellipsis: { showTitle: true },
      key: "shopName",
      width: 200,
    },
    {
      title: "类型",
      dataIndex: "ruleNameList",
      key: "ruleNameList",
      ellipsis: { showTitle: true },
      width: 300,
      render: (info) => {
        return (
          <Tooltip title={info ? info.join("，") : ""}>
            {info ? info.join("，") : ""}
          </Tooltip>
        )
      },
    },
    {
      title: "生效时间",
      dataIndex: "timeRule",
      ellipsis: { showTitle: true },
      key: "timeRule",
      width: 200,
      render: (info) => {
        let NewTimeData = []
        if (info) {
          info.map((v, i) => {
            NewTimeData.push(v.startTime + "~" + v.endTime)
          })
        }
        return (
          <Tooltip title={NewTimeData ? NewTimeData.join("，") : ""}>
            {NewTimeData ? NewTimeData.join("，") : ""}
          </Tooltip>
        )
      },
    },
    {
      title: "不生效设备",
      dataIndex: "cdKeyList",
      ellipsis: { showTitle: true },
      key: "cdKeyList",
      width: 200,
      render: (info) => {
        return (
          <Tooltip title={info ? info.join("，") : ""}>
            {info ? info.join("，") : ""}
          </Tooltip>
        )
      },
    },
    {
      title: "开关",
      dataIndex: "isOpen",
      ellipsis: { showTitle: true },
      width: 100,
      fixed: "right",
      render: (info, rowInfo) => {
        return (
          <Switch
            checked={info == 1 ? true : false}
            onChange={(checked) => onEdit(rowInfo.id, checked)}
          />
        )
      },
    },
    {
      title: "操作",
      ellipsis: { showTitle: true },
      fixed: "right",
      width: 150,
      render: (info, rowInfo) => {
        return (
          <>
            <Button
              style={{ width: "30px" }}
              type="link"
              onClick={() => onJumpEdit(rowInfo)}
              disabled={rowInfo.shopName === "全部" ? true : false}
            >
              编辑
            </Button>
            <Popconfirm
              title="确认删除该条数据吗？"
              onConfirm={() => onDelete(rowInfo)}
              onCancel={() => {}}
              okText="确认"
              cancelText="取消"
            >
              <Button
                style={{ width: "30px", marginLeft: "20px" }}
                type="link"
                // style={{ marginLeft: "20px" }}
              >
                删除
              </Button>
            </Popconfirm>
          </>
        )
      },
    },
  ]
  return (
    // 称重列表
    <div>
      <Card>
        <div className={styles.loss_prevention_list_head}>
          <div className={styles.loss_prevention_list_head_search}>
            <div className={styles.loss_prevention_list_head_search_child}>
              规则名称：
              <PubInput
                onChange={(val) => {
                  setParamsInfo({
                    ...ParamsInfo,
                    name: val,
                  })
                }}
                placeholder="规则名称"
                value={ParamsInfo.name}
              />
            </div>
          </div>
          <div className={styles.loss_prevention_list_head_btn}>
            <Button onClick={handClearn}>重置</Button>
            <Button type="primary" onClick={handlSearch}>
              查询
            </Button>
          </div>
        </div>
      </Card>
      <Card style={{ marginTop: 16 }}>
        <div style={{ marginBottom: 20 }}>
          <Button type="primary" onClick={onJumpAdd}>
            新建规则
          </Button>
        </div>
        <div>
          <Table
            columns={columns}
            pagination={onTableChange()}
            dataSource={lossList}
            rowKey="id"
            loading={loading}
            scroll={{ x: 2000, y: window.screen.height * 0.49 }}
          />
        </div>
      </Card>
    </div>
  )
}

export default Index
