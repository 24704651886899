import React, { useEffect, useState, useRef } from "react"
import {
  Card,
  Button,
  Popconfirm,
  Table,
  Switch,
  message,
  Select,
  DatePicker,
  Tooltip,
} from "antd"
import * as OrphanServer from "../../services/orphan"
import { useHistory } from "react-router-dom"
import { SearchOutlined, SyncOutlined, PlusOutlined } from "@ant-design/icons"
import dayjs from "dayjs"
import styles from "./Index.module.css"
import PubInput from "../../../components/PubInput"

const { Option } = Select

function Index(props) {
  let history = useHistory()
  const [orphanList, setOrphanList] = useState([]) // 丢失商品规则列表
  const [shopList, setShopList] = useState([]) // 门店列表
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)

  const [ParamsInfo, setParamsInfo] = useState({
    pageNum: 1,
    pageSize: 20,
    name: undefined, // 商品名称
    shopId: undefined, // 门店ID
    isOpen: undefined,
  })

  // REF,动态值，影响表格接口刷新
  const refInfo = useRef({
    ParamsInfoRef: {}, //点击查询后的条件值
  })

  useEffect(() => {
    refInfo.current = {
      ParamsInfoRef: ParamsInfo,
    }
  }, [ParamsInfo])

  // 初始化
  useEffect(() => {
    setParamsInfo({ ...ParamsInfo, pageNum: 1 })
    // 丢失商品规则列表接口，
    setTimeout(getOrphanList, 0)

    // 门店列表
    getShopList()
  }, [])

  /**
   * 丢失商品规则列表
   */
  const getOrphanList = async () => {
    try {
      const { ParamsInfoRef } = refInfo?.current
      setLoading(true)
      const { code, data, msg } = await OrphanServer.orphan_goods_rulelist(
        ParamsInfoRef
      )
      if (code != 200 || data === null) {
        message.error(msg)
        setLoading(false)
        return
      }
      setOrphanList(data.list)
      setTotal(data.total)
      setLoading(false)
    } catch (error) {
      message.error("称重规则列表获取失败")
      setLoading(false)
    }
  }

  /**
   * 获取门店列表
   */
  const getShopList = async (index) => {
    try {
      const params = {
        pageNum: 1,
        pageSize: 1000,
        code: "",
      }
      const { code, msg, data } = await OrphanServer.Shop_List(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      setShopList(data?.list || [])
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 新建规则
   */
  const onJumpAdd = () => {
    history.push("/admin/orphan/addrules")
  }

  /**
   * 编辑称重规则
   */
  const onJumpEdit = (rowInfo) => {
    // console.log(rowInfo)
    // const Params = JSON.stringify(rowInfo)
    // history.push(`/admin/orphan/editrules?id=${rowInfo.id}`)
    history.push(`/admin/orphan/editrules?id=${rowInfo.id}`)
  }

  /**
   * 查询
   */
  const handlSearch = async () => {
    try {
      //页码为1
      setParamsInfo({ ...ParamsInfo, pageNum: 1 })
      //丢失商品规则列表
      setTimeout(getOrphanList, 0)
    } catch (error) {
      message.error(error)
      return
    }
  }

  /**
   * 重置
   */
  const handClearn = async () => {
    try {
      setParamsInfo({
        pageNum: 1,
        pageSize: 20,
        name: undefined, // 商品名称
        shopId: undefined, // 门店ID
        isOpen: undefined,
      })
      //丢失商品规则列表
      setTimeout(getOrphanList, 0)
    } catch (error) {
      console.log(error)
      return
    }
  }

  /**
   * 开关编辑
   */
  const onEdit = async (id, checked) => {
    console.log(checked)
    try {
      let params = { id: id, isOpen: checked ? 1 : 0 }
      const { code, data, msg } = await OrphanServer.orphan_good_ruleset(params)
      if (code != 200 || data === null) {
        message.error(msg)
        return
      }
      message.success(`操作成功`)
      //丢失商品规则列表
      setTimeout(getOrphanList, 0)
    } catch (error) {
      console.log(error)
      return
    }
  }

  /**
   * 删除
   */
  const onDelete = async (rowInfo) => {
    try {
      const { code, data, msg } = await OrphanServer.orphan_good_delete(
        rowInfo.id
      )
      if (code != 200 || data === null) {
        message.error(msg)
        return
      }
      message.success(`${rowInfo.name}删除成功`)
      //丢失商品规则列表
      setTimeout(getOrphanList, 0)
    } catch (error) {
      message.error("删除失败")
      return
    }
  }

  //分页
  const onTableChange = () => {
    const paginationProps = {
      showSizeChanger: false,
      showQuickJumper: false,
      showTotal: () => `共${total}条`,
      pageSize: ParamsInfo.pageSize,
      current: ParamsInfo.pageNum,
      total: total,
      onChange: (current) => {
        setParamsInfo({ ...ParamsInfo, pageNum: current })
        // 丢失商品规则列表
        setTimeout(getOrphanList, 0)
      },
    }
    return paginationProps
  }

  const columns = [
    {
      title: "规则编码",
      dataIndex: "id",
      key: "id",
      ellipsis: { showTitle: true },
      width: 200,
    },
    {
      title: "规则名称",
      dataIndex: "name",
      key: "name",
      ellipsis: { showTitle: true },
      width: 200,
    },
    {
      title: "超时X分未收银为丢失商品",
      dataIndex: "timeoutMinute",
      key: "timeoutMinute",
      ellipsis: { showTitle: true },
      width: 200,
    },
    {
      title: "适用门店",
      dataIndex: "shopName",
      key: "shopName",
      ellipsis: { showTitle: true },
      width: 200,
    },
    {
      title: "开关",
      dataIndex: "isOpen",
      ellipsis: { showTitle: true },
      width: 200,
      fixed: "right",
      render: (info, rowInfo) => {
        return (
          <Switch
            checked={info == 1 ? true : false}
            onChange={(checked) => onEdit(rowInfo.id, checked)}
          />
        )
      },
    },
    {
      title: "创建时间",
      dataIndex: "createdAt",
      key: "createdAt",
      ellipsis: { showTitle: true },
      width: 200,
    },
    {
      title: "操作",
      ellipsis: { showTitle: true },
      fixed: "right",
      width: 200,
      render: (info, rowInfo) => {
        return (
          <>
            <a onClick={() => onJumpEdit(rowInfo)}>编辑</a>
            <Popconfirm
              title="确认删除该条数据吗？"
              onConfirm={() => onDelete(rowInfo)}
              onCancel={() => {}}
              okText="确认"
              cancelText="取消"
            >
              <a style={{ marginLeft: "20px" }}>删除</a>
            </Popconfirm>
          </>
        )
      },
    },
  ]
  return (
    // 称重列表
    <div>
      <Card>
        <div className={styles.loss_prevention_list_head}>
          <div className={styles.loss_prevention_list_head_search}>
            <div className={styles.loss_prevention_list_head_search_child}>
              规则名称：
              <PubInput
                onChange={(val) => {
                  setParamsInfo({
                    ...ParamsInfo,
                    name: val,
                  })
                }}
                placeholder="规则名称"
                value={ParamsInfo.name}
              />
            </div>
            <div className={styles.loss_prevention_list_head_search_child}>
              门店名称：
              <Select
                // mode="multiple"
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                placeholder="请选择门店"
                style={{ width: 200 }}
                allowClear
                value={ParamsInfo.shopId}
                showSearch
                filterOption={(i, o) => o.props.children.includes(i)}
                onChange={(e) => {
                  setParamsInfo({
                    ...ParamsInfo,
                    shopId: e || undefined,
                  })
                }}
              >
                {shopList.map((item, index) => {
                  return (
                    <Option value={item?.shopId} key={index}>
                      {item?.shopName}
                    </Option>
                  )
                })}
              </Select>
            </div>
            <div className={styles.loss_prevention_list_head_search_child}>
              状态：
              <Select
                // mode="multiple"
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                placeholder="请选择开关"
                style={{ width: 200 }}
                allowClear
                value={ParamsInfo.isOpen}
                showSearch
                filterOption={(i, o) => o.props.children.includes(i)}
                onChange={(e) => {
                  setParamsInfo({
                    ...ParamsInfo,
                    isOpen: e || undefined,
                  })
                }}
              >
                <Option value={"1"} key={"1"}>
                  开
                </Option>
                <Option value={"0"} key={"0"}>
                  关
                </Option>
              </Select>
            </div>
          </div>
          <div className={styles.loss_prevention_list_head_btn}>
            <Button onClick={handClearn}>重置</Button>
            <Button type="primary" onClick={handlSearch}>
              查询
            </Button>
          </div>
        </div>
      </Card>
      <Card style={{ marginTop: 16 }}>
        <div style={{ marginBottom: 20 }}>
          <Button type="primary" onClick={onJumpAdd}>
            新建规则
          </Button>
        </div>
        <div>
          <Table
            columns={columns}
            pagination={onTableChange()}
            dataSource={orphanList}
            rowKey="id"
            loading={loading}
            scroll={{ x: 1000, y: window.screen.height * 0.49 }}
          />
        </div>
      </Card>
    </div>
  )
}

export default Index
